import { createClient } from '@supabase/supabase-js'

// Initialize Supabase
const supabaseUrl = 'https://vpofjhusanotmjnuraey.supabase.co/'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZwb2ZqaHVzYW5vdG1qbnVyYWV5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQxODU4MzIsImV4cCI6MjAzOTc2MTgzMn0.dL41H3_ZV3gOT54Q0et3JekpNmwHvAauwSIBzNPn9mE'
const supabase = createClient(supabaseUrl, supabaseKey)

const checkAuth = async () => {
    const authResults = await supabase.auth.getSession()
    const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"]
    const { data: { session } }  = authResults;
    const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r))

    if (!session && !isPublicPage) {
        window.location.href = '/login'
        return;
    }

    return session
}

export default checkAuth
export { supabase } // Export the Supabase client to use in other components
