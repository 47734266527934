import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Updated getLeadsContent to accept userId as an argument
export const getLeadsContent = createAsyncThunk(
  '/leads/content',
  async (accessToken) => {
    const response = await axios.get(
      `https://supabase-client-logged-in-api.rishagarwal.workers.dev/api/v0/list-leads`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  }
);

export const leadsSlice = createSlice({
  name: 'leads',
  initialState: {
    isLoading: false,
    leads: [],
    error: null, // Add error state to handle any API errors
  },
  reducers: {
    deleteLead: (state, action) => {
      const { index } = action.payload;
      state.leads.splice(index, 1);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeadsContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeadsContent.fulfilled, (state, action) => {
        const leads = action.payload.map((record) => ({
          name: record["name"], // Changed to 'Name'
          industry: record["industry"] || "Unknown Industry",
          phone_number: record["phone_number"] || "Unknown",
          called: record["called"] || "No",
          user_id: record["user_id"]
        }));
        state.leads = leads;
        state.isLoading = false;
      })
      .addCase(getLeadsContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message; // Handle any errors
      });
  }
});

export const { deleteLead } = leadsSlice.actions;
export default leadsSlice.reducer;