import React, { lazy, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { themeChange } from 'theme-change';
import checkAuth from './app/auth'; // Import the checkAuth function
import initializeApp from './app/init';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Register = lazy(() => import('./pages/Register'));
const Documentation = lazy(() => import('./pages/Documentation'));

// Initializing different libraries
initializeApp();

function App() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    themeChange(false); // 👆 Daisy UI themes initialization

    // Check for existing authentication session
    const checkUserSession = async () => {
      const session = await checkAuth();
      setSession(session);
      setLoading(false);
    };

    checkUserSession();
  }, []);

  if (loading) {
    // Optionally, you can show a loading spinner or placeholder while checking the session
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={!session ? <Login /> : <Navigate to="/app/welcome" replace />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={!session ? <Register /> : <Navigate to="/app/welcome" replace />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/app/*" element={session ? <Layout session={session} /> : <Navigate to="/login" replace />} /> {/* Pass session to Layout */}
        <Route path="*" element={<Navigate to={session ? "/app/welcome" : "/login"} replace />} />
      </Routes>
    </Router>
  );
}

export default App;
