// src/features/callCounter/callCounterSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    totalMinutes: 500, // Total minutes allowed
    remainingMinutes: 200 // Minutes remaining
};

export const callCounterSlice = createSlice({
    name: 'callCounter',
    initialState,
    reducers: {
        decrementMinutes: (state, action) => {
            state.remainingMinutes -= action.payload;
        },
        resetMinutes: (state) => {
            state.remainingMinutes = state.totalMinutes;
        },
        setMinutes: (state, action) => {
            state.remainingMinutes = action.payload;
        }
    }
});

export const { decrementMinutes, resetMinutes, setMinutes } = callCounterSlice.actions;
export default callCounterSlice.reducer;
